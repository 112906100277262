import React, { Component } from "react"
import firebase, { firebaseui } from "../utils/firebase"

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.firebaseUIElement = React.createRef()
  }

  componentDidMount() {
    const ui = new firebaseui.auth.AuthUI(firebase.auth())
    const redirectPath = localStorage.getItem("redirect-path") || "/list"

    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById("loader").style.display = "none"
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      // signInFlow: "popup",
      signInSuccessUrl: redirectPath,
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      // Terms of service url.
      // tosUrl: "<your-tos-url>",
      // // Privacy policy url.
      // privacyPolicyUrl: "<your-privacy-policy-url>",
    }

    ui.start("#firebaseui-auth-container", uiConfig)
  }

  render() {
    return (
      <>
        <div id="firebaseui-auth-container" ref={this.firebaseUIElement}></div>
        <div id="loader">Loading...</div>
      </>
    )
  }
}

export default LoginPage
